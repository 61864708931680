
          @import "src/styles/main.scss";
        
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  padding: 10px 32px;
  color: $color-white;
  width: 100%;
  font-family: $lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.25px;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;
  &.yellow {
    background-color: $color-yellow;
  }
  &.purple {
    margin-top: -15px;

    background-color: $color-purple;
  }
  &.grey {
    background-color: $color-grey;
  }
  &:disabled{
    background-color: $color-grey;
    cursor: not-allowed;
  }
  
}
