
          @import "src/styles/main.scss";
        
.agencyCheckBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  input {
    height: 20px;
    width: 20px;
  }
  input[type="checkbox"] {
    accent-color: $color-purple;
  }
}

.previewImage {
  max-height: 250px;
  max-width: 300px;
  height: 100%;
  width: 100%;
}
