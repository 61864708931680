
          @import "src/styles/main.scss";
        
.revenueBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 6px;
  border: 2px solid #dadada;
  padding: 24px;
  width: 100%;
}

.qrCode {
  height: 140px;
  width: 140px;
}

.lineLink {
  color: $color-black;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
