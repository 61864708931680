
          @import "src/styles/main.scss";
        
.payoutWrapper {
  background: linear-gradient(
      rgba(114, 46, 105, 0.75),
      rgba(114, 46, 105, 0.75)
    ),
    url("../../../../../common/assets/images/background.png");
  background-size: cover;
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .payOutContainer {
    border-radius: 16px;
    max-width: 500px;
    width: 100%;
    // min-height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    flex-direction: column;
    gap: 15px;
    padding: 15px;

    .btnWrapper {
      margin-block: 19px;
      & :first-child {
        margin-top: 0;
        padding: 0;
      }
      .specBtn {
        background-color: #ffa500;
      }
      button {
        width: 100%;
        background-color: #ffa500;
        // padding: 10px 32px 10px 32px;
        border: none;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 700;
        color: white;
        letter-spacing: 0.25px;
        line-height: 19.2px;

        display: flex;
        height: 49px;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
      }
      @media (max-width: 320px) {
        .btn {
          font-size: 14px;
        }
      }
    }
    .monthlyCardContainer {
      border-radius: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: center;
      overflow: auto;
      flex-direction: column;
      gap: 5px;
      // height: 100%;
      overflow: hidden;
      .fullWidthTextLeft {
        width: 100%;
        text-align: left;
        p {
          color: #fff;
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.272px;
          text-decoration-line: underline;
          margin: 0;
          padding-left: 10px;
        }
      }

      .monthlyCardWrapper {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 12px 10px 12px 10px;
        font-weight: 700;
        .flexSpaceBetween {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .textMiddle {
            color: #000;
            text-align: center;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.288px;
          }
          .textLeft,
          .textRight {
            color: #585ef2;
            cursor: pointer;
          }
        }
        .flexBottomCenter {
          padding: 15px 10px 15px 10px;
          padding-bottom: 0;
          text-align: center;
          .btn {
            // TODO:VRIFY
            cursor: pointer;
          }
          div {
            span {
              font-size: 30px;
            }
          }
          .btn {
            font-size: 17px;
          }
        }
        .textLeft,
        .textRight,
        .bottomText {
          font-size: 17px;
          cursor: pointer;
        }
        @media (max-width: 320px) {
          .textLeft,
          .textRight,
          .bottomText {
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
      .gridCardContainer {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 10px;
        .gridCardItem {
          :global {
            .varient {
              background-color: #0000ff;
            }
          }
          // gap: 0px;
          :first-child {
            color: #000;
            text-align: center;
            font-family: Lato;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.384px;
          }
          .itemText {
            p:first-child {
              text-align: center;
              font-family: Lato;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.256px;
            }
            p.subTitle {
              text-align: center;
              font-family: Lato;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.192px;
            }
          }
          :last-child {
            font-size: 16px;
            line-height: 19px;
          }
        }
        .varient {
          gap: revert;
          display: flex;
          padding: 6px 27px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: -15px;
          flex-shrink: 0;
        }
      }
      .listTableContainer {
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td {
          margin: 0;
          padding: 0;
          border: 0;
          outline: 0;
          font-size: 100%;
          vertical-align: baseline;
        }

        background-color: #ffffff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        text-align: center;

        tbody {
          display: block;
          max-height: 300px;
          overflow-y: scroll;
          height: 100%;
          padding-bottom: 30px;

          @media (height > 667px) {
            max-height: max-content;
            padding-bottom: 0px;
          }
        }
        thead,
        tr {
          display: table;
          table-layout: fixed;
          width: 100%;
        }
        thead {
          width: 100%;
          position: relative;
          text-align: justify;
          padding: 10px;
          background-color: white;
          color: black;
          border-bottom: 2px solid black;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
        tr td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;
          font-weight: 700;
          font-size: smaller;
        }
        th {
          white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // padding-inline: 30px;
          text-align: center;
          font-size: 13px;
          @media (max-width: 375px) {
            text-align: justify;
          }
        }
        tr {
          border-top: 2px solid #000;
          &:first-child {
            border-color: transparent;
          }
        }
        tbody tr {
          :first-child {
            padding-left: 10px;
          }
          :last-child {
            padding-right: 10px;
          }
        }
      }
    }
    .accountSelectionWrapper {
      background-color: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px 0px 50px 0px;
      border-radius: 15px;
      .container {
        border-radius: 15px;
        width: 255px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .mainTitle {
          padding: 10px 0px 10px 0px;
          font-weight: 700;
          font-size: 24px;
          line-height: 28.8px !important;
          color: #722e69;
          margin: 0;
          // TODO: verify figma
          text-wrap: nowrap;
        }
        .bankInfoWrapper {
          width: 100%;
          p {
            font-size: 12px;
            font-weight: 700;
            line-height: 14.4px;
            letter-spacing: 0.5px;
            text-align: left;
          }
          // .inputSpanBoxWrapp{
          //     padding: 8px 10px 8px 10px;
          //     gap: 12px;
          //     border-radius: 6px;
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     border: 1px solid black;
          //     height: 54px;

          //     input{
          //         margin: 0;
          //         padding: 0;
          //         accent-color: #722E69;
          //     }
          //     .bankName, .bankNumber{
          //         font-size: 12px;
          //         font-weight: 500;
          //         line-height: 14.4px;
          //         letter-spacing: 0.25px;
          //         text-align: left;
          //     }
          // }
        }
        .btnWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 0;
          button {
            background: #ffa500;
            padding: 8px 32px 8px 32px;
            border-radius: 6px;
            // opacity: 0px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.2px;
            letter-spacing: 0.25px;
            text-align: center;
            color: white;
          }
        }
        .bottomtext {
          width: 100%;
          text-align: right;
          span {
            font-size: 12px;
            font-weight: 700;
            line-height: 14.4px;
            letter-spacing: 0.5px;
            text-align: right;
            color: #0000ff;
          }
        }
        @media (max-width: 320px) {
          .mainTitle {
            font-size: 22px;
          }
        }
      }
    }
    .accountRegistration {
      background-color: white;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 50px;
      border-radius: 15px;
      .wrapper {
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        .titleWrapper {
          p {
            font-family: Lato;
            // font-size: 24px;
            font-size: 20px;
            font-weight: 700;
            line-height: 28.8px !important;
            letter-spacing: 0.016em;
            text-align: center;
            color: #722e69;
            margin: 0;
            // TODO: ask figma is wrong
            text-wrap: nowrap;
          }
        }
      }
      .Inputwrapper {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        .smallText {
          font-size: 12px;
          font-weight: 700;
          line-height: 14.4px;
          letter-spacing: 0.5px;
          text-align: left;
        }
        .btnWrapper {
          margin: 0;
        }
        .smallText {
          margin: 0;
        }
      }
      .btnWrapper {
        :last-child {
          background-color: #722e69;
        }
      }
      @media (max-width: 320px) {
        .wrapper {
          .titleWrapper {
            p {
              font-size: 18px;
            }
          }
        }
      }
    }
    .accountRegistrationConfirmation,
    .accountRegistrationCompleted,
    .specification {
      background-color: white;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 50px 0px 50px 0px;
      border-radius: 15px;
      text-align: center;
      min-height: 398px;
      .content {
        .title {
          font-family: Lato;
          font-size: 12px;
          font-weight: 700;
          line-height: 14.4px;
          letter-spacing: 0.5px;
          text-align: left;
        }
        .returnToLogin {
          width: 100%;
          font-family: Lato;
          font-size: 12px;
          font-weight: 700;
          line-height: 14.4px;
          letter-spacing: 0.5px;
          text-align: right;
          color: #0000ff;
          text-decoration: underline;
        }
      }
      .wrapper {
        border-radius: 15px;
        width: 255px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .titleWrapper {
          p {
            margin: 0;
            font-family: Lato;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.8px;
            letter-spacing: 0.016em;
            text-align: center;
            color: #722e69;
          }
        }
      }
      .btnWrapper {
        width: 100%;
        :first-child {
          background-color: #722e69;
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .imageContainer {
          width: 214px;
          height: 141px;
          position: relative;
          background-color: aliceblue;
          border: 2px solid black;
          p {
            position: absolute;
            font-size: 50px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #722e69;
            border: 4px 0px 0px 0px;
            transform: rotate(-30.08deg) translateY(-10px);
            left: 10px;
          }
        }
      }
      .transferDetailHeaderWrapper {
        border-bottom: 2px solid black;
        .transferDetailPara {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
          line-height: 28.8px;
          letter-spacing: 0.016em;
          text-align: center;
        }
      }
      .transferDetailContent {
        p {
          margin: 0;
          margin-top: 15px;
          // word-spacing: 273px;
          font-size: 12px;
          font-weight: 700;
          line-height: 19.2px;
          letter-spacing: 0.016em;
          text-align: center;
        }
      }
    }
    .specification {
      .content {
        gap: 18px;
        p {
          font-size: 12px;
          font-weight: 700;
          line-height: 14.4px;
          letter-spacing: 0.5px;
          text-align: center;
        }
      }
      .btnWrapper {
        button {
          background-color: #ffa500 !important;
        }
        .specBtn {
          height: 49px;
          padding: 8px 32px 8px 32px;
          font-size: 16px;
          font-weight: 700;
          line-height: 19.2px;
          letter-spacing: 0.25px;
          text-align: center;
          background-color: #ffa500;
        }
        .bottomDivText {
          width: 100%;
          margin-top: 10px;
          .returnToLogin {
            margin: 0;
            font-size: 12px;
            font-weight: 700;
            line-height: 14.4px;
            letter-spacing: 0.5px;
            text-align: right;
            color: #0000ff;
            background-color: transparent;
          }
        }
      }
    }
    .radioButton {
      background-color: white;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 50px 0px 50px 0px;
      border-radius: 15px;
      text-align: center;
      .wrapper {
        border-radius: 15px;
        width: 255px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .titleWrapper {
          p {
            margin: 0;
            font-family: Lato;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.8px;
            letter-spacing: 0.016em;
            text-align: center;
            color: #722e69;
          }
        }
      }
      .radioContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .radioContent {
          font-size: 12px;
          font-weight: 700;
          line-height: 14.4px;
          letter-spacing: 0.5px;
          text-align: left;

          input {
            margin-right: 10px;
            accent-color: #722e69;
          }
        }
      }
    }

    // max-height: 600px;
    .currencyCard {
      text-align: center;
      width: 100%;
      border-radius: 15px;
      padding-block: 15px;
      padding-inline: 25px;
      background-color: #ffffff;
      font-weight: bold;
      gap: 0;

      .title,
      .currency {
        margin: 0;
      }

      .title {
        font-size: 17px;
      }

      .currency {
        font-size: 38px;
      }

      .btn {
        font-weight: bold;
        width: 100%;
        font-size: 16px;
        margin-top: 20px;
        background-color: #ffa500;
        border-radius: 5px;
        outline: none;
        border: none;
        padding-block: 8px;
      }

      @media (max-width: 320px) {
        .title {
          font-size: 15px;
        }

        .currency {
          font-size: 25px;
        }

        .btn {
          font-size: 12px;
        }
      }
    }

    .gridCardContainer {
      display: grid;
      // grid-template-columns: auto auto;
      // grid-template-columns: 140px 140px;
      // grid-template-columns: 160px 160px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      place-content: center;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      @media (max-width: 320px) {
        grid-template-columns: 140px 140px;
      }

      @media (min-width: 375px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .gridCardItem {
        width: 100%;
        border-radius: 15px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        gap: 14px;
        justify-content: space-between;
        // padding-block: 20px;
        // padding-inline: 30px;
        padding: 15px 0px;
        p {
          line-height: 14px;
        }

        :first-child,
        :last-child {
          font-weight: bold;
          margin: 0;
        }

        :first-child {
          font-size: 16px;
          color: #000;
          text-align: center;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.256px;
        }
        p.subTitle {
          color: #000;
          text-align: center;
          font-family: Lato;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.192px;
          margin: 0;
        }

        :last-child {
          color: #000;
          text-align: center;
          font-family: Lato;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.384px;
        }
      }
      .varients {
        display: flex;
        padding: 6px 27px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: revert;
        flex-shrink: 0;
      }

      @media (max-width: 320px) {
        gap: 10px;

        .gridCardItem {
          padding-block: 10px;
          padding-inline: 20px;

          :first-child {
            font-size: 15px;
          }

          :last-child {
            font-size: 19px;
          }
        }
      }
    }

    .listContainer {
      width: 100%;
      background-color: white;
      border-radius: 15px;
      min-height: 200px;
      height: 235px;
      // TODO: there is some artificats need to fix it
      padding-bottom: 50px;
      font-weight: bold;

      .listContainerHeadWrapper {
        p {
          color: #000;
          font-family: Lato;
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.272px;
        }
      }

      .listContainerHead {
        font-size: 17px;
        padding: 12px;
        display: inline-block;
        width: 100%;
      }

      .listItemWrapper {
        height: 100%;
        overflow-y: auto;
        padding: 0;
        margin: 0;
        position: relative;
        .showMore {
          position: absolute;
          bottom: 0;
          width: 100%;
          background: white;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          text-align: end;
          span {
            padding: 5px;
            font-size: 15px;
            width: 100%;
            display: inline-block;
          }
        }

        .listItemContainer {
          height: 100%;
          overflow-y: auto;
          padding: 0;
          margin: 0;
          height: 150px;

          li {
            padding: 8px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            border-top: 2px solid black;
            padding-inline: 15px;

            .titleLeftText {
              font-size: 16px;
              .listItemWrapper {
                height: 100%;
                overflow-y: auto;
                padding: 0;
                margin: 0;
                position: relative;
                .showMore {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  background: white;
                  border-bottom-right-radius: 15px;
                  border-bottom-left-radius: 15px;
                  text-align: end;
                  span {
                    padding: 5px;
                    font-size: 15px;
                    width: 100%;
                    display: inline-block;
                  }
                }

                .listItemContainer {
                  height: 100%;
                  overflow-y: auto;
                  padding: 0;
                  margin: 0;
                  height: 150px;

                  li {
                    padding: 8px;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 2px solid black;
                    padding-inline: 15px;

                    // .titleLeftText {
                    //     font-size: 16px;
                    // }

                    // .titleRightText {
                    //     font-size: 16px;
                    // }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
