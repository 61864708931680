
          @import "src/styles/main.scss";
        
.logoWrapper {
  display: inline-block;
  .logo {
    width: 120px;
    height: 100%;
  }
}
.buttonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  gap: 10px;
  .link {
    font-weight: bold;
    text-align: center;
  }
}
.titleText {
  line-height: 1.33 !important;
}

.description {
  align-self: center;
  font-weight: 700;
  margin-bottom: 20px;
  width: 100%;
  li {
    margin: 8px 0;
    font-size: 14px;
  }
}
