
          @import "src/styles/main.scss";
        
.inputSpanBoxWrapp {
  padding: 8px 10px 8px 10px;
  gap: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  height: 54px;

  input {
    margin: 0;
    padding: 0;
    accent-color: #722e69;
  }
  .bankName,
  .bankNumber {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    letter-spacing: 0.25px;
    text-align: left;
  }
}
