
          @import "src/styles/main.scss";
        
.filterContainer {
  display: flex;
  gap: 15px;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;

  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .label {
      color: $color-black;
      font-family: $lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .searchInput {
      padding: 5px 16px;
      border-radius: 6px;
      background: $color-white;
      min-width: 280px;
      border: 1px solid $color-black;
    }

    .checkbox {
      height: 20px;
      width: 20px;
      accent-color: $color-purple;
    }

    .dateBox {
      padding: 1px 5px;
      border-radius: 6px;
      background: $color-white;
      font-family: $lato;
      border: 1px solid $color-black;
    }
  }
}

.tableDetailButton {
  display: flex;
  background: transparent;
  border: none;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.influencerTable {
  margin-top: 30px;
  margin-inline: 10px;
  width: 100%;
  align-self: center;
}

.checkMark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
