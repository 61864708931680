
          @import "src/styles/main.scss";
        
.lineLink {
  color: $color-black;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.qrCode {
  height: 140px;
  width: 140px;
}
