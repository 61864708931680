
          @import "src/styles/main.scss";
        
.container {
    border-radius: 12px;
    overflow: auto;
    margin: 20px;

    p {
        margin: 0;
    }

    .TopCardwrapper {
        width: 100%;
        display: flex;
        padding: 29px 58px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        background-color: white;
        border-radius: 12px;

        .titleWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            padding-bottom: 10px;

            .dropDrownWrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                .textMiddle{
                    color: #000;
                    text-align: center;
                    font-family: Lato;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.288px;
                }
                .textLeft, .textRight{
                    font-weight: 700;
                    color: #585EF2;
                    cursor: pointer;
                }
            }
        }

        .columnItemWrapper {
            display: flex;
            margin-top: 10px;

            .columnItem {
                display: flex;
                padding: 10px 20px;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                justify-content: center;
                align-items: center;
                &:not(:last-child){
                    border-right: 1px solid #000;
                }

                .columnItemTitle {
                    color: #000;
                    text-align: center;
                    font-family: Lato;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: 0.25px;
                }

                .columnItemValue {
                    color: #000;
                    text-align: center;
                    font-family: Lato;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: 0.25px;
                }
                @media (max-width: 1020px) {
                    .columnItemValue{
                        font-size: 18px;
                    }
                    .columnItemTitle{
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .tableWrapper {
        margin-top: 10px;
        table{
            text-align: left;
            tbody{
                td:nth-child(4) {
                    text-align: left;
                }
            }
        }
    }
}