
          @import "src/styles/main.scss";
        
.card {
  background-color: $color-white;
  border-radius: 16px;
  max-width: 500px;
  width: 100%;
  min-height: 600px;
  padding: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  flex-direction: column;
  gap: 24px;
}

@media (max-width: 420px) {
  .card {
    padding: 32px;
    gap: 18px;
    min-height: 400px;
  }
}
