
          @import "src/styles/main.scss";
        
.table-container {
  background: $color-white;
  border-radius: 12px;
  overflow: auto;

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    .tableTitle {
      margin: 10px 0px;
    }

    .filter-button {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      max-width: 100px;
      width: 100%;
    }
  }

  .paginationButton {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .loadMoreButton {
      background: transparent;
      margin: 8px;
      border: none;
      color: $color-blue;
      font-weight: bold;
      text-align: end;
      text-decoration: underline;
      cursor: pointer;
      &:disabled {
        color: $color-grey;
      }
    }
  }
}

table {
  border-spacing: 0px;
  width: 100%;
  font-size: 16px;
  white-space: nowrap;
  thead {
    background-color: $color-purple;
    color: $color-white;
    tr {
      th {
        padding: 8px 15px;
        min-width: 100px;
      }
      th:nth-child(1) {
        min-width: 40px;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 8px 15px;
        border-bottom: 1px solid lightgrey;
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td:nth-child(4) {
        text-align: center;
      }
    }
  }
}
