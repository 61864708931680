
          @import "src/styles/main.scss";
        
.buttonsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  gap: 10px;
  .link {
    font-weight: bold;
    text-align: center;
  }
}

.description {
  align-self: center;
  // max-width: 250px;
  font-weight: 700;
  margin-left: 25px;
  li {
    margin: 10px 0;
  }
}
