
          @import "src/styles/main.scss";
        
.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 6px;

  .label {
    color: $color-black;
    font-family: $lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .select {
    width: 100%;
    height: 40px;
    padding: 11px 16px;
    border-radius: 6px;
    border: 1px solid $color-black;
    flex: 1;
    padding-right: 30px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-image: url("../../../assets/icons/dropdown.svg");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;

    &.sm {
      padding: 5px 16px;
    }
  }
}
