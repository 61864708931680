
          @import "src/styles/main.scss";
        
.monthlyCardWrapper {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 12px 10px 12px 10px;
  font-weight: 700;
  .flexSpaceBetween {
    width: 100%;
    display: flex;
    // max-width: 286px;
    justify-content: space-between;
    align-items: flex-start;

    .textMiddle {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.288px;
    }
    .textLeft,
    .textRight {
      color: #585ef2;
      cursor: pointer;
    }
  }
  .flexBottomCenter {
    padding: 15px 10px 15px 10px;
    padding-bottom: 0;
    text-align: center;
    .btn {
      // TODO:VRIFY
      cursor: pointer;
    }
    div {
      span {
        font-size: 30px;
      }
    }
    .btn {
      font-size: 17px;
    }
  }
  .textLeft,
  .textRight,
  .bottomText {
    font-size: 17px;
    cursor: pointer;
  }
  @media (max-width: 320px) {
    .textLeft,
    .textRight,
    .bottomText {
      font-size: 15px;
      cursor: pointer;
    }
  }
}
