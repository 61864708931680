
          @import "src/styles/main.scss";
        
.adminDashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 30px;

  .headerButtonContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
}
