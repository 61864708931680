
          @import "src/styles/main.scss";
        
.form-layout {
  // background-color: rgba($color-purple, 0.8);
  background: linear-gradient(
      rgba($color-purple, 0.75),
      rgba($color-purple, 0.75)
    ),
    url("../../../assets/images/background.png");
  background-size: cover;
  min-height: 100vh;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  gap: 15px;
}
