
          @import "src/styles/main.scss";
        
.agencyCheckBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  input {
    height: 20px;
    width: 20px;
  }
  input[type="checkbox"] {
    accent-color: $color-purple;
  }
}

.previewImage {
  max-height: 250px;
  max-width: 300px;
  height: 100%;
  width: 100%;
}
.outlinedBtn{
  border: 2px solid #ffa500;
  background: transparent;
  color: #ffa500;
}
.linkTo{
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  color: #000;
  font-weight: 700;
  text-wrap: wrap;
  margin-block: 0;
  .link{
    text-decoration: none;
    color: #0b57d0;
  }
}

.logoWrapper{
  display: inline-block;
  .logo{
    width: 120px;
    height: 100%;
  }
}