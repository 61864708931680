
          @import "src/styles/main.scss";
        
.gridCardItem {
    width: 100%;
    border-radius: 15px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: space-between;
    // padding-block: 20px;
    // padding-inline: 30px;

    padding: 15px 0px;
    align-items: center;
    &.fc_reverse{
        flex-direction: column-reverse;
    }
    p{
      line-height: 14px;  
    }

    :first-child,
    :last-child {
        font-weight: bold;
        margin: 0;
    }

    :first-child {
        font-size: 16px;
        color: #000;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.256px;
    }
    p.subTitle{
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.192px;
        margin: 0;
    }

    :last-child {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.384px;
    }
    .gapUnset{
        gap: unset !important;
    }
    .varient{
        line-height: 14px;
        text-wrap: wrap;
        width: min-content;
    }
}
.varients{
    display: flex;
    padding: 6px 27px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: revert;
    flex-shrink: 0;
}

.gridCardItem {
    @media (max-width: 320px) {
        padding-block: 10px;

        :first-child {
            font-size: 15px;
        }

        :last-child {
            font-size: 19px;
        }
    }
}