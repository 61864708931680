
          @import "src/styles/main.scss";
        
.aside {
  width: 200px;
  background-color: #722e69;
  color: $color-white;
  min-height: 100vh;

  header {
    padding: 25px;
    width: 100%;
    min-width: 200px;

    h2,
    h3 {
      margin: 0px;
    }
    h2 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.32px;
      cursor: pointer;
    }
    h3 {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.224px;
    }
  }
  menu {
    width: 100%;
    padding-left: 0px;

    .linkItem {
      text-decoration: none;
      color: $color-white;
      font-weight: 700;
      cursor: pointer;
    }

    li {
      width: 100%;
      list-style: none;
      padding: 20px 0px 20px 30px;
      position: relative;
      &:hover {
        padding-left: 35px;
        transition: all ease 0.2s;
        background-color: #dbcad8;
        border-left: 3px solid $color-white;
      }
    }
  }
}

@media (max-width: 420px) {
  .aside {
    display: none;
  }
}
